<template>
    <div class="container">
        <div class="center_out h-full">
            <div class="card login-card">
                <div class="login-card-1" id="login-card-1">
                    <!-- <img src="/images/mountain.png" alt=""> -->
                    <!-- <img src="/images/login-image.png" style="width:75%;height:auto;" > -->
                </div>
                <div class="login-card-2">

                    <img src="/images/logo.svg" alt="">
                    <div class="card-inner">
                        <p>Bitte melden Sie sich am Portal an:</p>

                        <form action="" @submit.prevent="submitForm">
                            <div class="from-group mt-2">
                                <input type="text" class="form-field" placeholder="Email-Adresse"
                                    v-model="account.email">
                            </div>
                            <div class="from-group mt-4">
                                <input type="password" class="form-field" placeholder="Passwort"
                                    v-model="account.password">
                            </div>

                            <div class="mt-4">
                                <vs-alert :active.sync="showError" closable close-icon="close">{{errorMessage}}
                                </vs-alert>
                            </div>

                            <div class="lower-con">
                                <button type="submit" class="from-btn">Anmelden</button>
                                <div class="mt-4">
                                    <router-link class="router-link" :to="{ name: 'ResetPassword' }">Passwort vergessen?
                                    </router-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <small class="version mt-3">Version: {{version}}</small>

        </div>

    </div>


</template>

<script>
import Vue from 'vue';



export default Vue.extend({
  name: 'Login',
  
  data() {
    return {
        showError: false,
        errorMessage: '',
        account: {
            email: '',
            password: '',
        }
    }
  },

    computed: {
        version() {
            if (GIT_DESCRIBE !== null) {
		// // console.log(error('App version git hash', GIT_DESCRIBE.hash)
                return GIT_DESCRIBE.tag + " - " + GIT_DESCRIBE.hash
            } else {
                return "no-version"
            }
        }
    },

  methods:{
    async submitForm(){
        if (this.account.email === '') {
            // this.errorMessage = "Email-Adresse überprüfen"
            // this.showError = true
            this.$vs.notify({
                color: "danger",
                position: "bottom-right",
                title: 'Email-Adresse fehlerhaft, bitte überprüfen!',
            })

            return false
        }
        if (this.account.password === '') {
            // this.errorMessage = "Passwort überprüfen"
            // this.showError = true
            this.$vs.notify({
                color: "danger",
                position: "bottom-right",
                title: 'Passwort fehlerhaft, bitte überprüfen!',
            })

            return false 
        }

        await this.$store.dispatch('auth/createLogin', this.account)


        if (await this.$store.dispatch('auth/isAuthenticated')) {
            this.$router.push('/dashboard');
        } else {
            // this.errorMessage = "Eingaben überprüfen"
            // this.showError = true
            this.$vs.notify({
                color: "danger",
                position: "bottom-right",
                title: 'Eingabe fehlerhaft, bitte überprüfen!',
            })

            return false
        }


        this.$vs.notify({
            color: "success",
            position: "bottom-right",
            title: 'Willkommen im BOS-ID Portal',
        })

    }      
  }
});
</script>


<style scoped>

#login-card-1 {
    background: url('../../public/images/login-image.png');
    min-height: 100%;
    max-height: 30vh;
    background-repeat: no-repeat;
    background-position: center 25%;
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.version {
    font-size: 0.75em;
    color: rgb(202, 202, 202);
}

</style>
